import { ReactNode, FunctionComponent } from 'react'
import { Link } from 'gatsby'
import {
  renderRichText,
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from 'gatsby-source-contentful/rich-text'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { Edges } from 'types'

export * from './bandcamp'

export function removeEdges<T>(edgesOb: Edges<T>): T[] {
  return edgesOb.edges.map((edge) => ({ ...edge.node }))
}

export type RichTextType = RenderRichTextData<ContentfulRichTextGatsbyReference>

export function getComponentFromRT(richTextField: RichTextType, cn?: string): ReactNode {
  const Text: FunctionComponent = ({ children }) => <p className={cn}>{children}</p>

  const Bold: FunctionComponent = ({ children }) => <span className="bold">{children}</span>

  const options = {
    renderMark: {
      [MARKS.BOLD]: (text: ReactNode) => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_: any, children: ReactNode) => <Text>{children}</Text>,
      [INLINES.HYPERLINK]: (node: any, children: ReactNode) => {
        const { uri } = node.data
        const isExternal = uri.startsWith('http') || uri.startsWith('mailto')
        return isExternal ? (
          <a href={uri} target="_blank" rel="noreferrer nofollow noopener">
            {children}
          </a>
        ) : (
          <Link to={uri}>{children}</Link>
        )
      },
    },
  }

  return renderRichText(richTextField, options)
}
